import axios from "@/axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

const fetchUserProgramDetails = createAsyncThunk(
  "dashboard/fetchUserProgramDetails",
  async (params) => {
    const response = await axios.get(
      "grade-book/setting/role-based-program-children",
      { params }
    );
    return response?.data?.data;
  }
);
const fetchSingleProgramDetails = createAsyncThunk(
  "dashboard/fetchSingleProgramDetails",
  async ({ params } = {}) => {
    const response = await axios.get(
      "grade-book/dashboard/course-student-count",
      { params }
    );
    return { params, data: response?.data?.data };
  }
);
const fetchGradeBookList = createAsyncThunk(
  "dashboard/fetchGradeBookList",
  async ({ params }) => {
    const response = await axios.get("grade-book/dashboard/gb-by-program", {
      params,
    });
    return { params, data: response?.data?.data };
  }
);
const createRegularGradeBook = createAsyncThunk(
  "dashboard/createRegularGradeBook",
  async ({ postData }) => {
    const response = await axios.post(
      "grade-book/dashboard/regular-gb",
      postData
    );
    return response?.data;
  }
);
const updateGradeBookData = createAsyncThunk(
  "dashboard/updateGradeBookData",
  async ({ data }) => {
    const response = await axios.put(`grade-book/dashboard`, data);
    return response?.data;
  }
);
const deleteGradeBook = createAsyncThunk(
  "dashboard/deleteGradeBook",
  async ({ params }) => {
    const response = await axios.delete("grade-book/dashboard", { params });
    return response?.data;
  }
);

const updateGradeBook = createAsyncThunk(
  "dashboard/updateGradeBook",
  async ({ updateData }) => {
    const response = await axios.post("grade-book/dashboard", updateData);
    return response?.data;
  }
);
const fetchSingleGradeBook = createAsyncThunk(
  "dashboard/fetchGradeBook",
  async ({ params, isStudent = false }) => {
    const response = await axios.get(
      `grade-book/${isStudent ? "student/gb" : "dashboard"}`,
      { params }
    );
    return response?.data?.data;
  }
);
const fetchAcademicYear = createAsyncThunk(
  "dashboard/fetchAcademicYear",
  async () => {
    const response = await axios.get("academic-year");
    return response?.data?.data;
  }
);

const fetchGrades = createAsyncThunk(
  "dashboard/fetchGrades",
  async ({ isStudent = false }) => {
    const response = await axios.get(
      `grade-book/${isStudent ? "student" : "dashboard"}/grades`
    );
    return response?.data?.data;
  }
);

const fetchCourseExams = createAsyncThunk(
  "dashboard/fetchCourseExams",
  async (params) => {
    const response = await axios.get("grade-book/dashboard/exams", {
      params,
    });
    return { params, data: response?.data?.data };
  }
);

const fetchStudentPrograms = createAsyncThunk(
  "dashboard/fetchStudentPrograms",
  async (params) => {
    const response = await axios.get("grade-book/student/programs", {
      params,
    });
    return response?.data?.data;
  }
);

const fetchStudentProgramDetails = createAsyncThunk(
  "dashboard/fetchStudentProgramDetails",
  async (params) => {
    const response = await axios.get(
      "grade-book/student/program-with-children",
      { params }
    );
    return response?.data?.data;
  }
);

const fetchStudentGradeBooks = createAsyncThunk(
  "dashboard/fetchStudentGradeBooks",
  async (params) => {
    const response = await axios.get("grade-book/student/gb-by-academic-no", {
      params,
    });
    return { params, data: response?.data?.data };
  }
);

const addNewColumnHeader = createAsyncThunk(
  "newColumnHeader",
  async ({ payload }) => {
    const response = await axios.put(
      "grade-book/dashboard/new-column-header",
      payload
    );
    return response?.data?.data;
  }
);

const addAverageMarksColumn = createAsyncThunk(
  "addAverageMarks",
  async ({ payload }) => {
    const response = await axios.put(
      "grade-book/dashboard/average-mark-column",
      payload
    );
    return response?.data?.data;
  }
);

const addExtraOrReduceMarks = createAsyncThunk(
  "addExtraOrReduceMarks",
  async ({ payload, isExtraMarks }) => {
    const type = isExtraMarks ? "extra-mark-column" : "reduce-mark-column";
    const response = await axios.put(`grade-book/dashboard/${type}`, payload);
    return response?.data?.data;
  }
);

const updatePassingCriteria = createAsyncThunk(
  "updatePassingCriteria",
  async ({ payload }) => {
    const response = await axios.put(
      `grade-book/dashboard/passing-criteria`,
      payload
    );
    return response?.data?.data;
  }
);

const updateGradeBookStatus = createAsyncThunk(
  "dashboard/updateGradeBookStatus",
  async ({ data }) => {
    const response = await axios.put(`grade-book/dashboard/gb-status`, data);
    return response?.data?.data;
  }
);

const fetchGbStudents = createAsyncThunk(
  "dashboard/fetchGbStudents",
  async ({ params }) => {
    const response = await axios.get("grade-book/dashboard/gb-students", {
      params,
    });
    return response?.data?.data;
  }
);

const addGradeRevision = createAsyncThunk(
  "dashboard/addGradeRevision",
  async ({ data }) => {
    const response = await axios.put(
      `grade-book/dashboard/gb-students-revision`,
      data
    );
    return response?.data?.data;
  }
);

const updateGbStudents = createAsyncThunk(
  "dashboard/updateGbStudents",
  async ({ data }) => {
    const response = await axios.put(`grade-book/dashboard/gb-students`, data);
    return response?.data?.data;
  }
);

const addWeightAgeColumn = createAsyncThunk(
  "dashboard/addWeightAgeColumn",
  async ({ payload }) => {
    const response = await axios.put(
      `grade-book/dashboard/weightage-column`,
      payload
    );
    return response?.data?.data;
  }
);

const importStudents = createAsyncThunk(
  "dashboard/importStudents",
  async ({ payload }) => {
    const response = await axios.put(
      `grade-book/dashboard/import-students`,
      payload
    );
    return response?.data?.data;
  }
);

const removeColumn = createAsyncThunk(
  "dashboard/removeColumn",
  async ({ payload }) => {
    const response = await axios.put(
      `grade-book/dashboard/delete-column`,
      payload
    );
    return response?.data?.data;
  }
);

const createResitGradeBook = createAsyncThunk(
  "dashboard/createResitGradeBook",
  async ({ postData }) => {
    const response = await axios.post(
      "grade-book/dashboard/resit-gb",
      postData
    );
    return response?.data;
  }
);

const fetchStudentPreviousGb = createAsyncThunk(
  "dashboard/fetchStudentPreviousGb",
  async ({ params, isStudent = false }) => {
    const url = isStudent
      ? "student/regular-gb"
      : "dashboard/student-regular-gb";
    const response = await axios.get(`grade-book/${url}`, { params });
    return response?.data?.data;
  }
);

const addResitExtraMark = createAsyncThunk(
  "dashboard/addResitExtraMark",
  async ({ payload }) => {
    const response = await axios.put(
      `grade-book/dashboard/resit-extra-mark`,
      payload
    );
    return response?.data?.data;
  }
);

const deleteExtraMark = createAsyncThunk(
  "dashboard/deleteExtraMark",
  async ({ payload }) => {
    const response = await axios.put(
      `grade-book/dashboard/delete-extra-mark`,
      payload
    );
    return response?.data?.data;
  }
);

const deleteResitCondition = createAsyncThunk(
  "dashboard/deleteResitCondition",
  async ({ payload }) => {
    const response = await axios.put(
      `grade-book/dashboard/delete-resit-condition`,
      payload
    );
    return response?.data?.data;
  }
);

const addResitCondition = createAsyncThunk(
  "dashboard/addResitCondition",
  async ({ payload }) => {
    const response = await axios.put(
      `grade-book/dashboard/resit-condition`,
      payload
    );
    return response?.data?.data;
  }
);

const fetchImpactData = createAsyncThunk(
  "dashboard/fetchImpactData",
  async ({ postData }) => {
    const response = await axios.post(
      "grade-book/dashboard/resit-impact-table",
      postData
    );
    return response?.data?.data;
  }
);

const applyResitCondition = createAsyncThunk(
  "dashboard/applyResitCondition",
  async ({ payload }) => {
    const response = await axios.put(
      `grade-book/dashboard/resit-equivalence-apply`,
      payload
    );
    return response?.data?.data;
  }
);

const editGradeBookData = createAsyncThunk(
  "dashboard/editGradeBookData",
  async ({ data }) => {
    const response = await axios.put(`grade-book/dashboard/edit-gb`, data);
    return response?.data?.data;
  }
);

const importResitStudents = createAsyncThunk(
  "dashboard/importResitStudents",
  async ({ payload }) => {
    const response = await axios.put(
      `grade-book/dashboard/resit-import-students`,
      payload
    );
    return response?.data?.data;
  }
);

export {
  fetchUserProgramDetails,
  fetchGradeBookList,
  createRegularGradeBook,
  updateGradeBookData,
  deleteGradeBook,
  updateGradeBook,
  fetchSingleGradeBook,
  fetchAcademicYear,
  fetchSingleProgramDetails,
  fetchGrades,
  fetchCourseExams,
  fetchStudentPrograms,
  fetchStudentProgramDetails,
  fetchStudentGradeBooks,
  addNewColumnHeader,
  addAverageMarksColumn,
  addExtraOrReduceMarks,
  updatePassingCriteria,
  updateGradeBookStatus,
  fetchGbStudents,
  addGradeRevision,
  updateGbStudents,
  addWeightAgeColumn,
  importStudents,
  removeColumn,
  createResitGradeBook,
  fetchStudentPreviousGb,
  addResitExtraMark,
  deleteExtraMark,
  deleteResitCondition,
  addResitCondition,
  fetchImpactData,
  applyResitCondition,
  editGradeBookData,
  importResitStudents,
};
