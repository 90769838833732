import { constructSettingsList } from "@/Utils/jsUtils";
import { fromJS } from "immutable";

const fetchSettingsPending = (state /* , action */) => {
  state = state.set("isLoading", true).set("isApiCalled", false);
  return state;
};
const fetchSettingsFullFiled = (state, action) => {
  const { programId, data } = action.payload;
  state = state
    .set("isLoading", false)
    .set("isApiCalled", true)
    .setIn(["programSettings", programId], constructSettingsList(fromJS(data)));
  return state;
};
const fetchSettingsRejected = (state, action) => {
  state = state
    .set("isLoading", false)
    .set("isApiCalled", true)
    .set("errorMsg", action.error);
  return state;
};
const postSettingsFullFiled = (state, action) => {
  const { programId, data } = action.payload;
  state = state
    .set("isLoading", false)
    .setIn(["programSettings", programId], fromJS(data));
  return state;
};
const fetchExamsDataFullFiled = (state, action) => {
  const programId = action.payload.programId;
  const data = action.payload.data;
  state = state
    .set("isLoading", false)
    .setIn(["examTypes", programId], fromJS(data.examType))
    .setIn(["examCategories", programId], fromJS(data.category));
  return state;
};
const putSettingsFullFiled = (state) => {
  state = state.set("isLoading", false);
  return state;
};
const postExamTypesFullFiled = (state) => {
  state = state.set("isLoading", false);
  return state;
};
const postExamCategoriesFullFiled = (state) => {
  state = state.set("isLoading", false);
  return state;
};
const fetchAttemptTypeFullFiled = (state, action) => {
  const data = action.payload;
  state = state.set("isLoading", false).set("attemptType", fromJS(data));
  return state;
};
const deleteExamTypeFullFiled = (state) => {
  state = state.set("isLoading", false).set("errorMsg", "Remove Successful");
  return state;
};
const deleteCategoriesFullFiled = (state) => {
  state = state.set("isLoading", false).set("errorMsg", "Remove Successful");
  return state;
};

const fetchExamTypesFullFiled = (state) => {
  state = state.set("isLoading", false);
  // .set("programSettings", fromJS(action.payload));
  return state;
};
const fetchCategoriesFullFiled = (state) => {
  state = state.set("isLoading", false);
  // .set("programSettings", fromJS(action.payload));
  return state;
};
const fetchProgramsFullFiled = (state, action) => {
  state = state.set("isLoading", false).set("programs", fromJS(action.payload));
  return state;
};
export {
  fetchSettingsPending,
  fetchSettingsFullFiled,
  fetchSettingsRejected,
  postSettingsFullFiled,
  fetchCategoriesFullFiled,
  fetchExamsDataFullFiled,
  putSettingsFullFiled,
  fetchExamTypesFullFiled,
  postExamTypesFullFiled,
  postExamCategoriesFullFiled,
  fetchAttemptTypeFullFiled,
  deleteCategoriesFullFiled,
  deleteExamTypeFullFiled,
  fetchProgramsFullFiled,
};
