import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

import config from "@/Utils/config";

import { authReducer } from "./authData/slice";
import { settingsReducer } from "./settingsData/slice";
import { dashBoardReducer } from "./GradeBookDashboard/slice";

import { apiSlice } from "./apis/util";
const { ENV } = config;
export const store = configureStore({
  reducer: {
    authData: authReducer,
    settingsData: settingsReducer,
    dashboardData: dashBoardReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false,
    }).concat(apiSlice.middleware);
  },
  devTools: ENV !== "production", // Use redux-devtools-extension
});
setupListeners(store.dispatch);
