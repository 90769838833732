export const GB_TYPE_REGULAR = "REGULAR";
export const GB_TYPE_RESIT = "RESIT";
export const TABLE_FORMAT_CATEGORY = "CATEGORY";
export const TABLE_FORMAT_EXAM_TYPE = "EXAM_TYPE";
export const PASSING_CRITERIA_EXAM_TYPE = "EXAM_TYPE";
export const PASSING_CRITERIA_CATEGORIES = "CATEGORY";
export const PASSING_CRITERIA_TOTAL_PERCENTAGE = "TOTAL_PERCENTAGE";
export const PASSING_CRITERIA_ALL = "ALL";
export const GB_STATUS_DRAFT = "DRAFT";
export const GB_STATUS_COMPLETED = "COMPLETED";
export const GB_STATUS_PUBLISHED = "PUBLISHED";
export const COLUMN_OPTION_SORT = "CAN_SORT";
export const COLUMN_OPTION_DELETE = "CAN_DELETE";
export const COLUMN_OPTION_CONDITION_COLUMNS = "CAN_ADD_CONDITION_COLUMNS";

// Student result column types
export const TYPE_REG_NO = "REG_NO";
export const TYPE_STUDENT_NAME = "STUDENT_NAME";
export const TYPE_TOTAL_MARK = "TOTAL_MARK";
export const TYPE_MARK = "MARK";
export const TYPE_WEIGHTAGE = "WEIGHTAGE";
export const TYPE_EXTRA_MARK = "EXTRA_MARK";
export const TYPE_REDUCE_MARK = "REDUCE_MARK";
export const TYPE_AVERAGE_MARK = "AVERAGE_MARK";
export const TYPE_GRADE = "GRADE";
export const TYPE_INFERENCE = "INFERENCE";
export const TYPE_CATEGORY = "CATEGORY";
export const TYPE_EXAM = "EXAM";
export const TYPE_COMBINED_WEIGHTAGE = "COMBINED_WEIGHTAGE";
export const TYPE_CATEGORY_WEIGHTAGE = "CATEGORY_WEIGHTAGE";
export const TYPE_EXTRA_MARKS_AND_CONDITIONS = "EXTRA_MARK_AND_CONDITION";
export const TYPE_FINAL_GRADE_REPORT = "FINAL_GRADE_REPORT";
export const TYPE_STUDENT_DETAILS = "STUDENT_DETAILS";
export const TYPE_STUDENT_STATUS = "STUDENT_STATUS";
export const TYPE_CONSOLIDATION_GB = "RESIT_CONSOLIDATION";
export const TYPE_COLUMN_FOR_EXTRA_MARK = "RESIT_EXTRA_MARK";
export const TYPE_COLUMN_TO_APPLY_CONDITIONS = "APPLY_CONDITION_COLUMN";
export const TYPE_FILTER_BY_STUDENT_STATUS = "FILTER_BY";
export const TYPE_EXTRA_MARKS_ADDED = "EXTRA_MARK_VERTICAL_COLUMN";
export const TYPE_RESIT_CONDITIONS_ADDED = "RESIT_CONDITIONS_VERTICAL_COLUMN";
export const TYPE_APPLY_CONDITIONS = "APPLY_CONDITION";
export const TYPE_FINAL_MARK = "FINAL_MARK";
export const TYPE_FINAL_GRADE = "FINAL_GRADE";
export const TYPE_FINAL_INFERENCE = "FINAL_INFERENCE";

export const RESIT_CONDITION_CURRENT_MARK = "CURRENT_MARK";
export const RESIT_CONDITION_PASS_MARK = "MINIMUM_PASS_MARK";
export const RESIT_CONDITION_EQUIVALENCE = "EQUIVALENCE_TO_REGULAR_EXAMS";

export const EQUIVALENCE_RESIT_TOTAL_MARK = "CONSOLIDATE_TOTAL_MARK";
export const EQUIVALENCE_RESIT_EXAM = "RESIT_EXAM";
