import { createSlice } from "@reduxjs/toolkit";
import { List, Map } from "immutable";
import {
  fetchUserProgramDetails,
  fetchGradeBookList,
  createRegularGradeBook,
  updateGradeBookData,
  deleteGradeBook,
  updateGradeBook,
  fetchSingleGradeBook,
  fetchSingleProgramDetails,
  fetchAcademicYear,
  fetchGrades,
  fetchCourseExams,
  fetchStudentPrograms,
  fetchStudentProgramDetails,
  fetchStudentGradeBooks,
  addNewColumnHeader,
  addAverageMarksColumn,
  addExtraOrReduceMarks,
  updatePassingCriteria,
  updateGradeBookStatus,
  fetchGbStudents,
  addGradeRevision,
  updateGbStudents,
  addWeightAgeColumn,
  importStudents,
  removeColumn,
  createResitGradeBook,
  fetchStudentPreviousGb,
  addResitExtraMark,
  deleteExtraMark,
  deleteResitCondition,
  addResitCondition,
  fetchImpactData,
  applyResitCondition,
  editGradeBookData,
  importResitStudents,
} from "./thunk";
import {
  fulfilledCallback,
  pendingCallBack,
  rejectedCallBack,
} from "@/store/thunkUtil";
import {
  fetchAcademicYearFullFiled,
  fetchUserProgramDetailsFullFiled,
  fetchSingleProgramDetailsFullFiled,
  fetchGradeBookListFullFiled,
  fetchSingleFullFiled,
  createGradeBookFullFiled,
  fetchGradeBookListPending,
  updateGradeBookFullFiled,
  deleteGradeBookFullFiled,
  fetchGradesFulfilled,
  fetchCourseExamsFulfilled,
  fetchStudentProgramsFulfilled,
  fetchStudentProgramDetailsFullFiled,
  fetchStudentGradeBooksFulfilled,
  fetchGbStudentsFulfilled,
  fetchPreviousGbFulfilled,
  fetchImpactDataFulfilled,
} from "./utils";
const dashBoardDataSlice = createSlice({
  name: "authData",
  initialState: Map({
    isLoading: false,
    isApiCalled: false,
    errorMsg: "",
    academicYear: List(),
    userProgramDetails: Map(),
    programData: Map(),
    gradeBookList: Map(),
    gradeBookData: Map(),
    constructedData: Map(),
    grades: List(),
    filterBy: Map(),
    courseExams: Map(),
    studentPrograms: List(),
    toasterMessage: Map(),
    gbStudents: List(),
    studentPreviousGb: Map(),
    impactTableData: Map(),
  }),
  reducers: {
    setData(state, action) {
      state = state.merge(action.payload);
      return state;
    },
    setIsLoading(state) {
      state = state.set("isLoading", true);
      return state;
    },
    setIsApiCalled(state, action) {
      state = state.set("isApiCalled", action.payload);
      return state;
    },
  },
  extraReducers(builder) {
    //fetchDashboardAcademicYear starting
    builder.addCase(fetchAcademicYear.pending, pendingCallBack);
    builder.addCase(fetchAcademicYear.fulfilled, fetchAcademicYearFullFiled);
    builder.addCase(fetchAcademicYear.rejected, rejectedCallBack);
    //fetchDashboardAcademicYear ending

    //fetchDashboardProgram starting
    builder.addCase(fetchUserProgramDetails.pending, pendingCallBack);
    builder.addCase(
      fetchUserProgramDetails.fulfilled,
      fetchUserProgramDetailsFullFiled
    );
    builder.addCase(fetchUserProgramDetails.rejected, rejectedCallBack);
    builder.addCase(fetchSingleProgramDetails.pending, pendingCallBack);
    builder.addCase(
      fetchSingleProgramDetails.fulfilled,
      fetchSingleProgramDetailsFullFiled
    );
    builder.addCase(fetchSingleProgramDetails.rejected, rejectedCallBack);
    //fetchDashboardProgram ending

    //fetchGradeBookList starting
    builder.addCase(fetchGradeBookList.pending, fetchGradeBookListPending);
    builder.addCase(fetchGradeBookList.fulfilled, fetchGradeBookListFullFiled);
    builder.addCase(fetchGradeBookList.rejected, rejectedCallBack);
    //fetchGradeBookList ending

    //createRegularGradeBook starting
    builder.addCase(createRegularGradeBook.pending, pendingCallBack);
    builder.addCase(createRegularGradeBook.fulfilled, createGradeBookFullFiled);
    builder.addCase(createRegularGradeBook.rejected, rejectedCallBack);
    //createRegularGradeBook ending

    //updateGradeBook starting
    builder.addCase(updateGradeBookData.pending, pendingCallBack);
    builder.addCase(updateGradeBookData.fulfilled, updateGradeBookFullFiled);
    builder.addCase(updateGradeBookData.rejected, rejectedCallBack);
    //updateGradeBook ending

    //deleteGradeBook starting
    builder.addCase(deleteGradeBook.pending, pendingCallBack);
    builder.addCase(deleteGradeBook.fulfilled, deleteGradeBookFullFiled);
    builder.addCase(deleteGradeBook.rejected, rejectedCallBack);
    //deleteGradeBook ending

    //updateGradeBook starting
    builder.addCase(updateGradeBook.pending, pendingCallBack);
    builder.addCase(updateGradeBook.fulfilled, fetchGradeBookListFullFiled);
    builder.addCase(updateGradeBook.rejected, rejectedCallBack);
    //updateGradeBook ending

    //fetchSingle starting
    builder.addCase(fetchSingleGradeBook.pending, pendingCallBack);
    builder.addCase(fetchSingleGradeBook.fulfilled, fetchSingleFullFiled);
    builder.addCase(fetchSingleGradeBook.rejected, rejectedCallBack);
    //fetchSingle ending

    //fetchGrades starting
    builder.addCase(fetchGrades.pending, pendingCallBack);
    builder.addCase(fetchGrades.fulfilled, fetchGradesFulfilled);
    builder.addCase(fetchGrades.rejected, rejectedCallBack);
    //fetchGrades ending

    //fetchCourseExams starting
    builder.addCase(fetchCourseExams.pending, pendingCallBack);
    builder.addCase(fetchCourseExams.fulfilled, fetchCourseExamsFulfilled);
    builder.addCase(fetchCourseExams.rejected, rejectedCallBack);
    //fetchCourseExams ending

    //fetchStudentPrograms starting
    builder.addCase(fetchStudentPrograms.pending, pendingCallBack);
    builder.addCase(
      fetchStudentPrograms.fulfilled,
      fetchStudentProgramsFulfilled
    );
    builder.addCase(fetchStudentPrograms.rejected, rejectedCallBack);
    //fetchStudentPrograms ending

    //fetchStudentProgramDetails starting
    builder.addCase(fetchStudentProgramDetails.pending, pendingCallBack);
    builder.addCase(
      fetchStudentProgramDetails.fulfilled,
      fetchStudentProgramDetailsFullFiled
    );
    builder.addCase(fetchStudentProgramDetails.rejected, rejectedCallBack);
    //fetchStudentProgramDetails ending

    //fetchStudentGradeBooks starting
    builder.addCase(fetchStudentGradeBooks.pending, pendingCallBack);
    builder.addCase(
      fetchStudentGradeBooks.fulfilled,
      fetchStudentGradeBooksFulfilled
    );
    builder.addCase(fetchStudentGradeBooks.rejected, rejectedCallBack);
    //fetchStudentGradeBooks ending

    //addNewColumnHeader starting
    builder.addCase(addNewColumnHeader.pending, pendingCallBack);
    builder.addCase(addNewColumnHeader.fulfilled, fulfilledCallback);
    builder.addCase(addNewColumnHeader.rejected, rejectedCallBack);
    //addNewColumnHeader ending

    //addAverageMarksColumn starting
    builder.addCase(addAverageMarksColumn.pending, pendingCallBack);
    builder.addCase(addAverageMarksColumn.fulfilled, fulfilledCallback);
    builder.addCase(addAverageMarksColumn.rejected, rejectedCallBack);
    //addAverageMarksColumn ending

    //addExtraOrReduceMarks starting
    builder.addCase(addExtraOrReduceMarks.pending, pendingCallBack);
    builder.addCase(addExtraOrReduceMarks.fulfilled, fulfilledCallback);
    builder.addCase(addExtraOrReduceMarks.rejected, rejectedCallBack);
    //addExtraOrReduceMarks ending

    //updatePassingCriteria starting
    builder.addCase(updatePassingCriteria.pending, pendingCallBack);
    builder.addCase(updatePassingCriteria.fulfilled, fulfilledCallback);
    builder.addCase(updatePassingCriteria.rejected, rejectedCallBack);
    //updatePassingCriteria ending

    //updateGradeBookStatus starting
    builder.addCase(updateGradeBookStatus.pending, pendingCallBack);
    builder.addCase(updateGradeBookStatus.fulfilled, fulfilledCallback);
    builder.addCase(updateGradeBookStatus.rejected, rejectedCallBack);
    //updateGradeBookStatus ending

    //fetchGbStudents starting
    builder.addCase(fetchGbStudents.pending, pendingCallBack);
    builder.addCase(fetchGbStudents.fulfilled, fetchGbStudentsFulfilled);
    builder.addCase(fetchGbStudents.rejected, rejectedCallBack);
    //fetchGbStudents ending

    //addGradeRevision starting
    builder.addCase(addGradeRevision.pending, pendingCallBack);
    builder.addCase(addGradeRevision.fulfilled, fulfilledCallback);
    builder.addCase(addGradeRevision.rejected, rejectedCallBack);
    //addGradeRevision ending

    //updateGbStudents starting
    builder.addCase(updateGbStudents.pending, pendingCallBack);
    builder.addCase(updateGbStudents.fulfilled, fulfilledCallback);
    builder.addCase(updateGbStudents.rejected, rejectedCallBack);
    //updateGbStudents ending

    //addWeightAgeColumn starting
    builder.addCase(addWeightAgeColumn.pending, pendingCallBack);
    builder.addCase(addWeightAgeColumn.fulfilled, fulfilledCallback);
    builder.addCase(addWeightAgeColumn.rejected, rejectedCallBack);
    //addWeightAgeColumn ending

    //importStudents starting
    builder.addCase(importStudents.pending, pendingCallBack);
    builder.addCase(importStudents.fulfilled, fulfilledCallback);
    builder.addCase(importStudents.rejected, rejectedCallBack);
    //importStudents ending

    //removeColumn starting
    builder.addCase(removeColumn.pending, pendingCallBack);
    builder.addCase(removeColumn.fulfilled, fulfilledCallback);
    builder.addCase(removeColumn.rejected, rejectedCallBack);
    //removeColumn ending

    //createResitGradeBook starting
    builder.addCase(createResitGradeBook.pending, pendingCallBack);
    builder.addCase(createResitGradeBook.fulfilled, createGradeBookFullFiled);
    builder.addCase(createResitGradeBook.rejected, rejectedCallBack);
    //createResitGradeBook ending

    // fetchStudentPreviousGb starting
    builder.addCase(fetchStudentPreviousGb.pending, pendingCallBack);
    builder.addCase(fetchStudentPreviousGb.fulfilled, fetchPreviousGbFulfilled);
    builder.addCase(fetchStudentPreviousGb.rejected, rejectedCallBack);
    //fetchStudentPreviousGb ending

    // addResitExtraMark starting
    builder.addCase(addResitExtraMark.pending, pendingCallBack);
    builder.addCase(addResitExtraMark.fulfilled, fulfilledCallback);
    builder.addCase(addResitExtraMark.rejected, rejectedCallBack);
    //addResitExtraMark ending

    //deleteExtraMark starting
    builder.addCase(deleteExtraMark.pending, pendingCallBack);
    builder.addCase(deleteExtraMark.fulfilled, fulfilledCallback);
    builder.addCase(deleteExtraMark.rejected, rejectedCallBack);
    //deleteExtraMark ending

    //deleteResitCondition starting
    builder.addCase(deleteResitCondition.pending, pendingCallBack);
    builder.addCase(deleteResitCondition.fulfilled, fulfilledCallback);
    builder.addCase(deleteResitCondition.rejected, rejectedCallBack);
    //deleteResitCondition ending

    //addResitCondition starting
    builder.addCase(addResitCondition.pending, pendingCallBack);
    builder.addCase(addResitCondition.fulfilled, fulfilledCallback);
    builder.addCase(addResitCondition.rejected, rejectedCallBack);
    //addResitCondition ending

    //fetchImpactData starting
    builder.addCase(fetchImpactData.pending, pendingCallBack);
    builder.addCase(fetchImpactData.fulfilled, fetchImpactDataFulfilled);
    builder.addCase(fetchImpactData.rejected, rejectedCallBack);
    //fetchImpactData ending

    //applyResitCondition starting
    builder.addCase(applyResitCondition.pending, pendingCallBack);
    builder.addCase(applyResitCondition.fulfilled, fulfilledCallback);
    builder.addCase(applyResitCondition.rejected, rejectedCallBack);
    //applyResitCondition ending

    //editGradeBook starting
    builder.addCase(editGradeBookData.pending, pendingCallBack);
    builder.addCase(editGradeBookData.fulfilled, fulfilledCallback);
    builder.addCase(editGradeBookData.rejected, rejectedCallBack);
    //editGradeBook ending

    //importResitStudents starting
    builder.addCase(importResitStudents.pending, pendingCallBack);
    builder.addCase(importResitStudents.fulfilled, fulfilledCallback);
    builder.addCase(importResitStudents.rejected, rejectedCallBack);
    //importResitStudents ending
  },
});

export const dashBoardReducer = dashBoardDataSlice.reducer;
export const { setIsLoading, setIsApiCalled, setData } =
  dashBoardDataSlice.actions;
