import { useDispatch } from "react-redux";

export const useDispatchHook = (dispatchFunctions) => {
  const dispatch = useDispatch();
  const allDispatchFunctions = dispatchFunctions.map(
    (dFunction) =>
      (...Data) =>
        dispatch(dFunction(...Data))
  );
  return allDispatchFunctions;
};
