import { useEffect } from "react";
import { List, Map, OrderedMap, fromJS, isMap } from "immutable";
import { Tooltip, Typography } from "@mui/material";
import {
  responsiveFontSizeLarge,
  responsiveFontSizeMedium,
} from "./designUtils";
import RadioButtons from "@/components/common/Radio";
import { step2 } from "@/Modules/Settings/config";
import InfoIcon from "@mui/icons-material/Info";
import {
  PASSING_CRITERIA_ALL,
  PASSING_CRITERIA_CATEGORIES,
  PASSING_CRITERIA_EXAM_TYPE,
  PASSING_CRITERIA_TOTAL_PERCENTAGE,
} from "./constants";

const { allowToAddNewColumn, addPassingCriteria, conditionalHeader } = step2;
const {
  title,
  allowExtraMarks,
  allowReduceMarks,
  allowAverageMarks,
  allowWeightAge,
  allowEquivalence,
} = conditionalHeader;
const nestedSettings = [
  { key: allowExtraMarks, value: "allowExtraMarks" },
  { key: allowReduceMarks, value: "allowReduceMarks" },
  { key: allowAverageMarks, value: "allowAverageMarks" },
  { key: allowWeightAge, value: "allowWeightAge" },
  { key: allowEquivalence, value: "allowEquivalence" },
];

/*
Usage:merge the options array with select option
Arguments:{
  arg1:ImmutableList
  arg2:string (optional)
}
return:immutable List
*/
export const mergeOptionsWithSelect = (options, value = "") => {
  return fromJS([
    {
      name: "Select",
      value,
    },
  ]).merge(options);
};

/*
Usage:constructs options array with name:name and value:id
Arguments:{
  arg1:ImmutableList
  arg2:string (optional)
  arg3:string (optional)
}
return:immutable List
*/
export const constructOptions = (
  options,
  id = "_id",
  name = "name",
  code = "code"
) => {
  return options.map((option) =>
    Map({
      name: option.get(name, ""),
      value: option.get(id, ""),
      code: option.get(code, ""),
    })
  );
};

/*
Usage:encrypt the string
Arguments:{
  arg1:string
}
return:encrypted String
*/
export function eString(name) {
  if (name) {
    return window.btoa(name);
  }
  return "";
}
/*
Usage:decrypt the string
Arguments:{
  arg1:string
}
return:decrypted String
*/
export function dString(name) {
  if (name) {
    return window.atob(name);
  }
  return "";
}

export const getYearFromDate = ({ input = new Date() }) => {
  const date = new Date(input);
  const year = date.getFullYear();
  return year;
};

// Checks if the length of the text exceeds the maximum characters allowed.
// Creates the truncated text if necessary, adding ellipsis (...) at the end.
// Determines the tooltip content based on whether the text was truncated.
//Returns JSX with Tooltip and truncated text.

export const truncateTextWithTooltip = (text, maxChars) => {
  const isTextTruncated = text.length > maxChars;
  const truncatedText = isTextTruncated
    ? `${text.substring(0, maxChars)}...`
    : text;
  const tooltipContent = isTextTruncated ? text : "";
  return (
    <Tooltip title={tooltipContent} placement="top" arrow>
      <span>{truncatedText}</span>
    </Tooltip>
  );
};

export const constructTextWithInfoToolTip = (text, maxChars) => {
  const isTextTruncated = text.length > maxChars;
  const truncatedText = isTextTruncated
    ? `${text.substring(0, maxChars)}...`
    : text;
  const tooltipContent = isTextTruncated ? text : "";

  return (
    <div className="d-flex">
      <span>{truncatedText}</span>
      {isTextTruncated && (
        <Tooltip
          title={tooltipContent}
          placement="top-end"
          arrow
          className="shifted-info-tooltip"
        >
          <InfoIcon />
        </Tooltip>
      )}
    </div>
  );
};

export const NoDataMessage = ({ message }) => {
  return (
    <Typography
      className="text-center py-3 text-uppercase bold txt-light-grayish"
      sx={responsiveFontSizeMedium}
    >
      {message}
    </Typography>
  );
};

export function mergeListsAtPosition(originalList, indexToMerge, listToMerge) {
  const beforeIndex = originalList.slice(0, indexToMerge);
  const afterIndex = originalList.slice(indexToMerge);
  return beforeIndex.concat(listToMerge).concat(afterIndex.skip(1));
}

export const TablePermissionSettings = ({ handleChange, getValue }) => {
  const disableSetting = getValue("title") === "no";

  (function (value) {
    disableSetting &&
      nestedSettings.forEach((item) =>
        handleChange(item.value)({ target: { value } })
      );
  })("no");

  useEffect(() => {
    const tableSettings = document.getElementById("table-permission");
    let disable_shade = document.querySelector(".disable-shade");
    if (disableSetting && !disable_shade) {
      disable_shade = document.createElement("div");
      disable_shade.setAttribute("class", "disable-shade");
      tableSettings.appendChild(disable_shade);
    } else if (!disableSetting && disable_shade)
      tableSettings.removeChild(disable_shade);
  }, [disableSetting]);

  return (
    <div className="d-flex flex-column gap-3 my-1 text-black">
      <Typography className="bold text-black" sx={responsiveFontSizeLarge}>
        {step2.gradeBookTablePermission}
      </Typography>
      <div className="border rounded px-3 pe-0 py-1">
        <div className="d-flex justify-content-between align-items-center">
          <Typography className="text-nowrap" sx={responsiveFontSizeLarge}>
            {allowToAddNewColumn}
          </Typography>
          <RadioButtons
            value={getValue("allowToAddNewColumn")}
            handleChange={handleChange("allowToAddNewColumn")}
          />
        </div>
      </div>

      <div
        className="border rounded px-3 pe-0 py-1 mt-1 position-relative"
        id="table-permission"
      >
        <div className="d-flex justify-content-between align-items-center">
          <Typography className="text-nowrap" sx={responsiveFontSizeLarge}>
            {title}
          </Typography>
          <RadioButtons
            value={getValue("title")}
            handleChange={handleChange("title")}
          />
        </div>
        <div className="permission-setting-grid py-4">
          {nestedSettings.map((item) => (
            <div key={item.value}>
              <Typography className="text-nowrap" sx={responsiveFontSizeLarge}>
                {item.key}
              </Typography>
              <RadioButtons
                value={getValue(item.value)}
                handleChange={handleChange(item.value)}
                disabled={disableSetting}
              />
            </div>
          ))}
        </div>
      </div>

      <div className="border rounded px-3 pe-0 py-1 mt-1">
        <div className="d-flex justify-content-between align-items-center">
          <Typography className="text-nowrap" sx={responsiveFontSizeLarge}>
            {addPassingCriteria}
          </Typography>
          <RadioButtons
            value={getValue("addPassingCriteria")}
            handleChange={handleChange("addPassingCriteria")}
          />
        </div>
      </div>
    </div>
  );
};

export const padAndParseToTwoDigits = (value) => {
  const paddedValue = String(value).padStart(2, "0");
  return parseInt(paddedValue, 10);
};
export const padToTwoDigitString = (value) => {
  const paddedValue = String(value).padStart(2, "0");
  return paddedValue;
};
export const calculateTotalPages = (totalItems, itemsPerPage) => {
  return Math.ceil(totalItems / itemsPerPage);
};

export const paginateData = (data, currentPage, itemsPerPage) => {
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  return data.slice(startIndex, endIndex);
};

export function getInputMaxMark(max, inputNumber) {
  const exceeded = max <= inputNumber;
  return { inputNumber, exceeded, max };
}

export function getStudentAcademicNo() {
  return localStorage.getItem("academicNo");
}

export function getStudentName() {
  return localStorage.getItem("name");
}

export function getRole() {
  return localStorage.getItem("role") || "Super Admin";
}

/*
Usage:constructs filterKey for filter course in program
Arguments:{
  arg1:filterState
  arg2:programId
}
return:constructKey like program+term+year+curriculum+level
*/
export const constructFilterKey = (state, programId) => {
  let constructKey = "";
  const term = state.get("term", "");
  const year = state.get("year", "");
  const curriculum = state.get("curriculum", "");
  const level = state.get("level", "");
  if (programId) constructKey = programId;
  if (term) constructKey += `+${term}`;
  if (year) constructKey += `+${year}`;
  if (curriculum) constructKey += `+${curriculum}`;
  if (level) constructKey += `+${level}`;
  return constructKey;
};

export const constructCourseWiseList = (data) => {
  return data.reduce((newObj, item) => {
    const courseId = item.getIn(["hierarchy", "course", "_id"], "");
    return newObj.update(courseId, List(), (pre) => pre.push(item));
  }, Map());
};

const passingCriteriaKeys = [
  "examTypes",
  "categories",
  "totalPercentage",
  "all",
];

export const constructKey = (option) => {
  const examTypeId = option.get("_id", "");
  const attemptTypeId = option.getIn(["attemptType", "_id"], "");
  return `${examTypeId}+${attemptTypeId}`;
};
export const constructKeyWithCategory = (exam) => {
  const isFromGb = exam.get("isFromGB", false);
  const categoryId = exam.get("categoryId", "");
  const examKey = constructKey(exam);
  return isFromGb || !categoryId ? examKey : `${categoryId}+${examKey}`;
};

export const convertExamIntoMap = (data, groupKey = "_id") => {
  return data.reduce((newObj, item) => {
    const key = item.get(groupKey, "");
    const examTypes = item
      .get("examTypes", List())
      .reduce((examGroup, exam) => {
        const examKey = constructKeyWithCategory(exam);
        return examGroup.set(examKey, exam);
      }, OrderedMap());

    return newObj.set(key, item.set("examTypes", examTypes));
  }, OrderedMap());
};

// step 1 construction
const constructStep1 = (data) => {
  let categories = data.get("categories", List());
  categories = convertExamIntoMap(categories);

  let GBTypes = data.get("GBTypes", List());
  GBTypes = convertExamIntoMap(GBTypes, "type");

  return Map({ categories, GBTypes });
};

const convertPermissionIntoBoolean = (data) => {
  return data.map((value) => {
    if (typeof value === "boolean") return value;
    if (isMap(value)) return convertPermissionIntoBoolean(value);
    return value === "yes";
  });
};

const convertPermissionIntoString = (data) => {
  return data.map((value) => {
    if (isMap(value)) return convertPermissionIntoString(value);
    return value ? "yes" : "no";
  });
};

// step 2 construction
export const constructStep2 = (data, toString = false) => {
  const permission = data.get("permission", Map());
  const convertFunc = toString
    ? convertPermissionIntoString
    : convertPermissionIntoBoolean;
  const convertedPermissions = convertFunc(permission);
  return data.set("permission", convertedPermissions);
};

const constructPassingCriteriaObject = (data, key, isExamTypes = false) => {
  const updatedData = data.getIn(key, List()).reduce((newObj, exam) => {
    let key = "";
    const categoryId = exam.get("categoryId", "");
    const examTypeId = exam.get("_id", "");
    if (categoryId) key += categoryId;
    if (isExamTypes && examTypeId) key += key ? `+${examTypeId}` : examTypeId;
    return newObj.set(key, exam);
  }, OrderedMap());
  return data.setIn(key, updatedData);
};

// step 3 constructions
const constructStep3 = (data) => {
  return data.reduce((newObj, item) => {
    const gbType = item.get("GBType", "");
    item = constructPassingCriteria(item, true);
    return newObj.set(gbType, item);
  }, OrderedMap());
};

export const constructSettingsList = (data) => {
  let settings = data.get("settings", Map());
  if (settings.size === 0) return data;

  const step1 = settings.get("examCategoriesAndExamTypes", Map());
  const step2 = settings.get("tableSettings", Map());
  const step3 = settings.get("passingCriteria", List());
  const updatedStep1 = constructStep1(step1);
  const updatedStep2 = constructStep2(step2, true);
  const updatedStep3 = constructStep3(step3);
  settings = settings
    .set("examCategoriesAndExamTypes", updatedStep1)
    .set("tableSettings", updatedStep2)
    .set("passingCriteria", updatedStep3);

  return data.set("settings", settings);
};

const convertIntoValues = (data, key) => {
  const updatedData = data.getIn(key, Map()).toList();
  return data.setIn(key, updatedData);
};
const convertExamIntoList = (data) => {
  return data.map((item) => convertIntoValues(item, ["examTypes"])).toList();
};

// revert step 1 construction
const revertConstructStep1 = (data) => {
  let categories = data.get("categories", Map());
  categories = convertExamIntoList(categories);

  let GBTypes = data.get("GBTypes", Map());
  GBTypes = convertExamIntoList(GBTypes);

  return Map({ categories, GBTypes });
};

export const deletePassingCriteriaKeys = (data, requiredKeys) => {
  passingCriteriaKeys.forEach((item) => {
    if (!requiredKeys.includes(item)) data = data.delete(item);
  });
  return data;
};

const convertIntoNumber = (data, key) => {
  const value = data.getIn(key, 0);
  return data.setIn(key, Number(value));
};

const constructPassingCriteriaList = (data, key) => {
  const updatedData = data
    .getIn(key, Map())
    .map((item) => convertIntoNumber(item, ["percentage"]))
    .toList();
  return data.setIn(key, updatedData);
};

export const constructPassingCriteria = (gbType, toObject = false) => {
  const type = gbType.get("type", "");
  const convertFunc = toObject
    ? constructPassingCriteriaObject
    : constructPassingCriteriaList;

  if (type === PASSING_CRITERIA_EXAM_TYPE) {
    gbType = convertFunc(gbType, ["examTypes"], true);
    return deletePassingCriteriaKeys(gbType, ["examTypes"]);
  }
  if (type === PASSING_CRITERIA_CATEGORIES) {
    const requiredKeys = ["totalPercentage", "categories"];
    gbType = convertIntoNumber(gbType, ["totalPercentage"]);
    gbType = convertFunc(gbType, ["categories"]);
    return deletePassingCriteriaKeys(gbType, requiredKeys);
  }
  if (type === PASSING_CRITERIA_TOTAL_PERCENTAGE) {
    gbType = convertIntoNumber(gbType, ["totalPercentage"]);
    return deletePassingCriteriaKeys(gbType, ["totalPercentage"]);
  }
  if (type === PASSING_CRITERIA_ALL) {
    gbType = convertIntoNumber(gbType, ["all", "totalPercentage"]);
    gbType = convertFunc(gbType, ["all", "examTypes"], true);
    gbType = convertFunc(gbType, ["all", "categories"]);
    return deletePassingCriteriaKeys(gbType, ["all"]);
  }
};

export const constructSettingsPayload = (settings) => {
  const step1 = settings.get("examCategoriesAndExamTypes", Map());
  const step2 = settings.get("tableSettings", Map());
  const step3 = settings.get("passingCriteria", Map());

  const revertedStep1 = revertConstructStep1(step1);
  const revertedStep2 = constructStep2(step2);
  const revertedStep3 = step3
    .map((gbType) => constructPassingCriteria(gbType))
    .toList();

  return Map({
    examCategoriesAndExamTypes: revertedStep1,
    tableSettings: revertedStep2,
    passingCriteria: revertedStep3,
  });
};
