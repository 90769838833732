const config = {
  ENV: process.env.ENV, //eslint-disable-line
  noAuth: process.env.noAuth, //eslint-disable-line
  API_URL: process.env.API_URL, //eslint-disable-line
  IFRAME_URL: process.env.IFRAME_URL, //eslint-disable-line
  authData: {
    accessToken:
      "eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI1ZWM5ZjllMTg5M2U1ZjIwMTQ2MWVhMjQiLCJlbXBsb3llZU9yQWNhZGVtaWNJZCI6IjM0MTUyMTI1MiIsImlhdCI6MTcwMzczOTM0NSwiZXhwIjoxNzE2Njk5MzQ1fQ.0M5WjDI23pG0Z5CIy1DHmk5cwtf9vSJkXSLwNcsQ2olu31MosAMWkiS6K7oCK_YYMS7RWf_Pq6mx5zfQg-irTQ",
    refreshToken:
      "eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI1ZWM5ZjllMTg5M2U1ZjIwMTQ2MWVhMjQiLCJlbXBsb3llZU9yQWNhZGVtaWNJZCI6IjM0MTUyMTI1MiIsInR5cGUiOiJTVEFGRiIsImlhdCI6MTcwMzY2MDAwOCwiZXhwIjoxNzAzNjc0NDA4fQ.ZfmoXCCRQGWc-Ja0TqT9jzfJAKFw6pXi1RqVem8_R2d_YJXxtYdBxA0dMNBC_18LXIcMFKu8_XkMOA4lUfeOUA",
  },
};
export default config;
