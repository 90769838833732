import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { setCredentials, removeCredentials } from "@/store/authData/slice";
import { fromJS } from "immutable";
import config from "@/Utils/config";
import { getCookies } from "@/Hooks/AuthHook";
const { API_URL } = config;
const baseQuery = fetchBaseQuery({
  baseUrl: API_URL,
  prepareHeaders: (Headers, { getState }) => {
    const token = getState().authData.getIn(["authLogin", "accessToken"], "");
    if (token) Headers.set("authorization", `Bearer ${token}`);
    else {
      const accessToken = getCookies("accessToken");
      Headers.set("authorization", `Bearer ${accessToken}`);
    }
    return Headers;
  },
});

const baseQueryWithReAuth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result?.error?.originalStatus === 403) {
    const refreshResult = await baseQuery("/refresh", api, extraOptions);
    if (refreshResult?.data) {
      api.dispatch(setCredentials(fromJS(refreshResult.data)));
      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(removeCredentials());
    }
  }
  return result;
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithReAuth,
  endpoints: () => ({}),
});
