import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
const fetchUsers = createAsyncThunk("users/fetch", async () => {
  const response = await axios.get(
    "https://f60d6248-d4d6-47ec-93b3-31d04b707608.mock.pstmn.io/assignment-outcome/framework-details"
  );
  return response.data;
});

export { fetchUsers };
