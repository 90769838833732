import { fromJS } from "immutable";
import Data from "../../../public/locales/EN/translation.json";
import {
  GB_TYPE_REGULAR,
  GB_TYPE_RESIT,
  TABLE_FORMAT_CATEGORY,
  TABLE_FORMAT_EXAM_TYPE,
} from "@/Utils/constants";
const {
  MasterGradeBookSetting,
  common: { back, backToLogin, backToDashboard },
} = Data;
const { title, stepper, step1, step2, step3, examTranslations } =
  MasterGradeBookSetting;

export const staticGradeBookWithLabel = fromJS([
  {
    label: "Regular",
    value: GB_TYPE_REGULAR,
  },
  {
    label: "Resit",
    value: GB_TYPE_RESIT,
  },
]);

export const getTableFormatOptions = (hasCategory) => {
  let options = [
    {
      name: "Exam type + Total Mark + Grade",
      value: TABLE_FORMAT_EXAM_TYPE,
    },
  ];

  if (hasCategory) {
    options.unshift({
      name: "Category + Exam type + Total Mark + Grade",
      value: TABLE_FORMAT_CATEGORY,
    });
  }

  return fromJS(options);
};

const step2Data = fromJS({
  gradeBookTableFormat: {
    value: ["type"],
    updateKey: ["tableSettings", "type"],
  },
  allowToAddNewColumn: {
    value: ["permission", "newColumnHeader"],
    updateKey: ["tableSettings", "permission", "newColumnHeader"],
  },
  title: {
    value: ["permission", "newConditionalHeader", "allow"],
    updateKey: ["tableSettings", "permission", "newConditionalHeader", "allow"],
  },
  allowExtraMarks: {
    value: ["permission", "newConditionalHeader", "extraMark"],
    updateKey: [
      "tableSettings",
      "permission",
      "newConditionalHeader",
      "extraMark",
    ],
  },
  allowReduceMarks: {
    value: ["permission", "newConditionalHeader", "reduceMark"],
    updateKey: [
      "tableSettings",
      "permission",
      "newConditionalHeader",
      "reduceMark",
    ],
  },
  allowAverageMarks: {
    value: ["permission", "newConditionalHeader", "averageMark"],
    updateKey: [
      "tableSettings",
      "permission",
      "newConditionalHeader",
      "averageMark",
    ],
  },
  allowWeightAge: {
    value: ["permission", "newConditionalHeader", "weightage"],
    updateKey: [
      "tableSettings",
      "permission",
      "newConditionalHeader",
      "weightage",
    ],
  },
  allowEquivalence: {
    value: ["permission", "newConditionalHeader", "equivalence"],
    updateKey: [
      "tableSettings",
      "permission",
      "newConditionalHeader",
      "equivalence",
    ],
  },
  addPassingCriteria: {
    value: ["permission", "passingCriteria"],
    updateKey: ["tableSettings", "permission", "passingCriteria"],
  },
  failedStudentMarks: {
    value: ["resit", "allowFailedStudentMarksUpTo"],
    updateKey: ["tableSettings", "resit", "allowFailedStudentMarksUpTo"],
  },
  absentStudentMarks: {
    value: ["resit", "absentStudentMarksUpTo"],
    updateKey: ["tableSettings", "resit", "absentStudentMarksUpTo"],
  },
});
export {
  back,
  backToLogin,
  backToDashboard,
  title,
  stepper,
  step1,
  step2,
  step2Data,
  step3,
  examTranslations,
};
