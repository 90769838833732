import { Suspense, lazy } from "react";
import {
  BrowserRouter as Router,
  Route,
  Navigate,
  Routes,
  useSearchParams,
} from "react-router-dom";
const Login = lazy(() => import("@/Modules/Login"));
const GradeBook = lazy(() => import("@/Modules/Routes"));
const StudentDashboard = lazy(() => import("@/Modules/Student"));
import { setCookies, useIframeHook } from "@/Hooks/AuthHook";
import config from "@/Utils/config";
const { noAuth } = config;

const Home = () => {
  const [params] = useSearchParams();
  if (noAuth === true) {
    setCookies();
  }
  return (
    <Navigate
      to={`/auth/login?noAuth=${noAuth}&${params.toString()}`}
      replace
    />
  );
};

const App = () => {
  useIframeHook();
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />

        <Route
          path="/auth/*"
          element={
            <Suspense fallback={"loading"}>
              <Login />
            </Suspense>
          }
        />
        <Route
          path="/gradeBook/*"
          element={
            <Suspense fallback={"loading"}>
              <GradeBook />
            </Suspense>
          }
        />
        <Route
          path="/student/*"
          element={
            <Suspense fallback={"loading"}>
              <StudentDashboard />
            </Suspense>
          }
        />
        <Route path="*" element={<Home />} />
      </Routes>
    </Router>
  );
};

export default App;
