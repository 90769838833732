import { createSlice } from "@reduxjs/toolkit";
import { List, Map } from "immutable";
import {
  fetchSettings,
  postSettings,
  fetchExamsData,
  fetchExamType,
  fetchExamCategories,
  putSettings,
  postExamTypes,
  postExamCategories,
  fetchAttemptType,
  deleteCategories,
  deleteExamType,
  fetchPrograms,
} from "./thunk";
import {
  pendingCallBack,
  rejectedCallBack,
  rejectedDeleteCallBack,
} from "../thunkUtil";
import {
  fetchSettingsFullFiled,
  postSettingsFullFiled,
  fetchExamsDataFullFiled,
  fetchExamTypesFullFiled,
  fetchCategoriesFullFiled,
  putSettingsFullFiled,
  postExamCategoriesFullFiled,
  postExamTypesFullFiled,
  fetchAttemptTypeFullFiled,
  deleteCategoriesFullFiled,
  deleteExamTypeFullFiled,
  fetchProgramsFullFiled,
  fetchSettingsPending,
  fetchSettingsRejected,
} from "./util";

const settingsDataSlice = createSlice({
  name: "authData",
  initialState: Map({
    isLoading: false,
    isApiCalled: false,
    errorMsg: "",
    programSettings: Map(),
    examTypes: Map(),
    examCategories: Map(),
    attemptType: List(),
    programs: List(),
  }),
  reducers: {
    setData(state, action) {
      state = state.merge(action.payload);
      return state;
    },
    setIsLoading(state) {
      state = state.set("isLoading", true);
      return state;
    },
    setIsApiCalled(state, action) {
      state = state.set("isApiCalled", action.payload);
      return state;
    },
  },
  extraReducers(builder) {
    //fetchSettings starting
    builder.addCase(fetchSettings.pending, fetchSettingsPending);
    builder.addCase(fetchSettings.fulfilled, fetchSettingsFullFiled);
    builder.addCase(fetchSettings.rejected, fetchSettingsRejected);
    //fetchSettings ending

    //postSettings starting
    builder.addCase(postSettings.pending, pendingCallBack);
    builder.addCase(postSettings.fulfilled, postSettingsFullFiled);
    builder.addCase(postSettings.rejected, rejectedCallBack);
    //postSettings ending

    //putSettings starting
    builder.addCase(putSettings.pending, pendingCallBack);
    builder.addCase(putSettings.fulfilled, putSettingsFullFiled);
    builder.addCase(putSettings.rejected, rejectedCallBack);
    //putSettings ending

    //fetchExamType starting
    builder.addCase(fetchExamType.pending, pendingCallBack);
    builder.addCase(fetchExamType.fulfilled, fetchExamTypesFullFiled);
    builder.addCase(fetchExamType.rejected, rejectedCallBack);
    //fetchExamType ending

    //fetchCategories starting
    builder.addCase(fetchExamCategories.pending, pendingCallBack);
    builder.addCase(fetchExamCategories.fulfilled, fetchCategoriesFullFiled);
    builder.addCase(fetchExamCategories.rejected, rejectedCallBack);
    //fetchCategories ending

    //fetchExamsData starting
    builder.addCase(fetchExamsData.pending, pendingCallBack);
    builder.addCase(fetchExamsData.fulfilled, fetchExamsDataFullFiled);
    builder.addCase(fetchExamsData.rejected, rejectedCallBack);
    //fetchExamsData ending

    //fetchAttemType starting
    builder.addCase(fetchAttemptType.pending, pendingCallBack);
    builder.addCase(fetchAttemptType.fulfilled, fetchAttemptTypeFullFiled);
    builder.addCase(fetchAttemptType.rejected, rejectedCallBack);
    //fetchAttemType ending

    //postExamTypes starting
    builder.addCase(postExamTypes.pending, pendingCallBack);
    builder.addCase(postExamTypes.fulfilled, postExamTypesFullFiled);
    builder.addCase(postExamTypes.rejected, rejectedCallBack);
    //postExamTypes ending

    //postExamCategories starting
    builder.addCase(postExamCategories.pending, pendingCallBack);
    builder.addCase(postExamCategories.fulfilled, postExamCategoriesFullFiled);
    builder.addCase(postExamCategories.rejected, rejectedCallBack);
    //postExamCategories ending

    //deleteExamCategories starting
    builder.addCase(deleteCategories.pending, pendingCallBack);
    builder.addCase(deleteCategories.fulfilled, deleteCategoriesFullFiled);
    builder.addCase(deleteCategories.rejected, rejectedDeleteCallBack);
    //deleteExamCategories ending

    //deleteExamTypes starting
    builder.addCase(deleteExamType.pending, pendingCallBack);
    builder.addCase(deleteExamType.fulfilled, deleteExamTypeFullFiled);
    builder.addCase(deleteExamType.rejected, rejectedDeleteCallBack);
    //deleteExamTypes ending

    //fetchPrograms starting
    builder.addCase(fetchPrograms.pending, pendingCallBack);
    builder.addCase(fetchPrograms.fulfilled, fetchProgramsFullFiled);
    builder.addCase(fetchPrograms.rejected, rejectedCallBack);
    //fetchPrograms ending
  },
});

export const settingsReducer = settingsDataSlice.reducer;
export const { setIsLoading, setIsApiCalled, setData } =
  settingsDataSlice.actions;
