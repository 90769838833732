import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordion from "@mui/material/Accordion";
import { styled } from "@mui/material/styles";
export const responsiveFontSizeSmall = {
  fontSize: {
    xs: "0.625em", // 10px
    sm: "0.6875em", // 11px
    md: "0.75em", // 12px
    lg: "0.875em", // 14px
    xl: "0.875em", // 14px
    xxl: "0.9375em", // 15px
  },
};
export const responsiveFontSizeMedium = {
  fontSize: {
    xs: "0.6667em", // 12px
    sm: "0.7222em", // 13px
    md: "0.7778em", // 14px
    lg: "0.8889em", // 16px
    xl: "0.8889em", // 16px
    xxl: "0.9444em", // 17px
  },
};
export const responsiveFontSizeLarge = {
  fontSize: {
    xs: "0.7368em", // 14px
    sm: "0.7895em", // 15px
    md: "0.8421em", // 16px
    lg: "0.9474em", // 18px
    xl: "0.9474em", // 18px
    xxl: "1em", // 19px
  },
};
export const responsiveFontSizeExtraLarge = {
  fontSize: {
    xs: "0.7619em", // 16px
    sm: "0.8095em", // 17px
    md: "0.8571em", // 18px
    lg: "0.9524em", // 20px
    xl: "0.9524em", // 20px
    xxl: "1em", // 21px
  },
};

export const placeholderStyles = {
  "& input::placeholder": responsiveFontSizeMedium,
  "& input": { ...responsiveFontSizeMedium, padding: "12px 15px" },
  "& textarea::placeholder": responsiveFontSizeMedium,
};

export const Accordion = styled((props) => <MuiAccordion {...props} />)(() => ({
  borderRadius: "10px !important",
  "&:before": {
    display: "none",
  },
}));
export const AccordionSummary = styled((props) => (
  <MuiAccordionSummary {...props} />
))(() => ({
  minHeight: "40px",
  padding: "12px 12px 15px 12px",
  "& .MuiAccordionSummary-content": {
    margin: 0,
  },
}));
export const AccordionDetails = styled(MuiAccordionDetails)(() => ({
  padding: "12px 12px 15px 12px",
  background: "#FCFCFC",
  borderRadius: "10px !important",
}));
