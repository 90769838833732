import { FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import {
  responsiveFontSizeLarge,
  responsiveFontSizeExtraLarge,
} from "@/Utils/designUtils";
import { fromJS } from "immutable";
const radioIconStyle = {
  color: "#E8E8E8",
  "& .MuiSvgIcon-root": {
    ...responsiveFontSizeExtraLarge,
  },
  "&.Mui-checked": {
    color: "#444EFF",
  },
  "&.Mui-disabled&.Mui-checked": {
    color: "#A1A7FF",
  },
};
const yesOrNo = fromJS([
  { label: "Yes", value: "yes" },
  { label: "No", value: "no" },
]);
const RadioButtons = ({
  direction = "",
  value,
  handleChange,
  options = yesOrNo,
  disabled = false,
}) => (
  <RadioGroup
    aria-labelledby="demo-controlled-radio-buttons-group"
    name="controlled-radio-buttons-group"
    value={value}
    onChange={handleChange}
  >
    <div className={`d-flex ${direction}`}>
      {options.map((option) => {
        const value = option.get("value", "");
        const label = option.get("label", "");
        return (
          <FormControlLabel
            key={value}
            value={value}
            control={
              <Radio disableRipple disabled={disabled} sx={radioIconStyle} />
            }
            label={
              <Typography sx={responsiveFontSizeLarge}>{label}</Typography>
            }
          />
        );
      })}
    </div>
  </RadioGroup>
);

export default RadioButtons;
