const pendingCallBack = (state /* , action */) => {
  state = state.set("isLoading", true);
  return state;
};
const rejectedCallBack = (state, action) => {
  state = state.set("isLoading", false).set("errorMsg", action.error);
  return state;
};
const rejectedDeleteCallBack = (state) => {
  state = state.set("isLoading", false).set("errorMsg", "Remove Failed");
  return state;
};
const fulfilledCallback = (state) => {
  return state.set("isLoading", false);
};

export {
  pendingCallBack,
  rejectedCallBack,
  rejectedDeleteCallBack,
  fulfilledCallback,
};
