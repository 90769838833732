import { createSlice } from "@reduxjs/toolkit";
import { Map, fromJS } from "immutable";
import { fetchUsers } from "./thunk";
import { pendingCallBack, rejectedCallBack } from "../thunkUtil";
const authDataSlice = createSlice({
  name: "authData",
  initialState: Map({
    isLoading: false,
    errorMsg: "",
    authLogin: Map(),
  }),
  reducers: {
    setCredentials(state, action) {
      const authLogin = action.payload;
      state = state.set("authLogin", fromJS(authLogin));
      return state;
    },
    removeCredentials(state) {
      state = state.set("authLogin", Map());
      return state;
    },
    setIsLoading(state) {
      state = state.set("isLoading", true);
      return state;
    },
  },
  extraReducers(builder) {
    //starting
    builder.addCase(fetchUsers.pending, pendingCallBack);
    builder.addCase(fetchUsers.fulfilled, (state, action) => {
      state = state.set("isLoading", false).set("authLogin", action.payload);
      return state;
    });
    builder.addCase(fetchUsers.rejected, rejectedCallBack);
    //ending
  },
});

export const authReducer = authDataSlice.reducer;
export const { setIsLoading, setCredentials, removeCredentials } =
  authDataSlice.actions;
