import { useEffect } from "react";
import Cookies from "js-cookie";

import config from "@/Utils/config";
import { setCredentials } from "@/store/authData/slice";
import { useDispatchHook } from "./dispatchHook";
const { authData, IFRAME_URL } = config;
export const setCookies = (data = authData) => {
  const { accessToken, refreshToken } = data;
  Cookies.set("accessToken", accessToken, { secure: true });
  Cookies.set("refreshToken", refreshToken, { secure: true });
};

export const getCookies = (key) => {
  return Cookies.get(key);
};

export const clearCookies = () => {
  Cookies.remove("accessToken", { secure: true });
  Cookies.remove("refreshToken", { secure: true });
};

export const useIframeHook = () => {
  const [updateCredentials] = useDispatchHook([setCredentials]);

  useEffect(() => {
    window.addEventListener("message", handleParentMessage);
    return () => {
      window.removeEventListener("message", handleParentMessage);
    };
  }, []);

  const handleParentMessage = (event) => {
    // Check the origin to ensure the message is from the expected parent
    if (event.origin !== IFRAME_URL) return;
    const eventData = event.data;
    const receivedData =
      typeof eventData === "object" ? eventData : JSON.parse(event.data);
    setCookies(receivedData);
    localStorage.setItem("role", receivedData.role);
    if (receivedData.type === "student") {
      const studentName = `${receivedData.name.first} ${
        receivedData.name.middle ? receivedData.name.middle : ""
      } ${receivedData.name.last}`;
      localStorage.setItem("academicNo", receivedData.academicNo);
      localStorage.setItem("name", studentName);
    } else {
      localStorage.removeItem("academicNo");
      localStorage.removeItem("name");
    }

    updateCredentials(receivedData);
  };
};

export const useResendHook = (authData) => {
  useEffect(() => {
    let timer;
    if (authData.size === 0) {
      timer = setInterval(
        window.parent.postMessage("resendData", IFRAME_URL),
        2000
      );
    }
    return () => clearInterval(timer);
  }, []);
};
