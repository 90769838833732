import axios from "axios";
// import { getCookies } from "@/Hooks/AuthHook";
import config from "@/Utils/config";
import { clearCookies, getCookies, setCookies } from "./Hooks/AuthHook";
//----------------------------------utils start-----------------------------------------------------
const resConfig = (response) => {
  // const originalRequest = response.config;
  return response;
};
const reqConfig = (config) => {
  const token = getCookies("accessToken");
  if (token) config.headers["Authorization"] = "Bearer " + token;
  return config;
};
const reqError = () => {};
const resError = (error) => {
  const originalRequest = error.config;
  if (
    error.response.status !== undefined &&
    error.response.status === 401 &&
    check
  ) {
    alert("Your session has timed out. Please login again.");
    clearCookies();
    // history.push('/login');
    // window.location.reload(true);
    return Promise.reject(error);
  }

  if (
    error.response.status !== undefined &&
    error.response.status === 401 &&
    !check
  ) {
    originalRequest._retry = true;
    const refreshToken = getCookies("refreshToken");
    return axios
      .post(
        API_URL + "/user/refresh-token",
        { refreshToken, app: "DA" }
        // {
        //   headers: {
        //     Authorization: "Bearer " + refreshToken,
        //   },
        // }
      )
      .then((res) => {
        if (res.status === 200) {
          const tokenObject = {
            accessToken: res.data.data.access.token,
            refreshToken: res.data.data.refresh.token,
          };
          setCookies(tokenObject);
          originalRequest.headers["Authorization"] =
            "Bearer " + tokenObject.accessToken;
          // originalRequest.headers['Accept-Language'] = getLang();
          return axios(originalRequest);
        }
      })
      .catch(() => {
        check = true;
        alert("Your session has timed out. Please login again.");
        clearCookies();
        // history.push('/login');
        // window.location.reload(true);
      });
  }
  return Promise.reject(error);
};
//----------------------------------utils end-------------------------------------------------------

const { API_URL } = config;
const instance = axios.create({
  baseURL: API_URL,
});
var check = false;

// instance.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
// instance.defaults.headers.common["Cache-Control"] = "no-cache";
// instance.defaults.headers.common["Pragma"] = "no-cache";
// instance.defaults.headers.common["Expires"] = "0";

instance.interceptors.request.use(reqConfig, reqError);
instance.interceptors.response.use(resConfig, resError);

export default instance;
